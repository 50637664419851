var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "two-thirds" } },
            [
              _c("gov-heading", { attrs: { size: "l" } }, [
                _vm._v("Taxonomy: Organisations")
              ]),
              _c("gov-body", [
                _vm._v(
                  "\n        This page shows the list of organisations a user can select from when\n        referring a client to another service. Essentially, these are the\n        organisations a champion can refer 'from'.\n      "
                )
              ]),
              _c("gov-body", [
                _vm._v(
                  "\n        From this page, you can edit, delete, and add the organisations that\n        appear on the list.\n      "
                )
              ])
            ],
            1
          ),
          _vm.auth.isGlobalAdmin
            ? _c(
                "gov-grid-column",
                { attrs: { width: "one-third" } },
                [
                  _c(
                    "gov-button",
                    {
                      attrs: {
                        to: { name: "taxonomies-organisations-create" },
                        success: "",
                        expand: ""
                      }
                    },
                    [_vm._v("Add a new organisation")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("gov-section-break", { attrs: { size: "l" } }),
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "two-thirds" } },
            [
              _vm.loading
                ? _c("ck-loader")
                : [
                    _vm.taxonomies.length > 0
                      ? _c(
                          "gov-list",
                          { attrs: { bullet: "" } },
                          _vm._l(_vm.taxonomies, function(taxonomy) {
                            return _c(
                              "li",
                              { key: taxonomy.id },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(taxonomy.name) +
                                    " \n            "
                                ),
                                _vm.auth.isGlobalAdmin
                                  ? _c(
                                      "gov-link",
                                      {
                                        attrs: {
                                          to: {
                                            name:
                                              "taxonomies-organisations-edit",
                                            params: { taxonomy: taxonomy.id }
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              Edit\n            "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _c("gov-body", [_vm._v("No taxonomies.")])
                  ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }